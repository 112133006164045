import React from 'react'
import { Link } from 'react-router-dom'

export default class PageFooter extends React.Component {

    render() {

        return (

            <footer className='page-footer'>

                <Link to='/about'>About</Link>

                <span className='v-divider' />

                <Link to='/privacy-policy'>Privacy Policy</Link>

                <span className='v-divider' />

                <Link to='/terms'>Terms</Link>

            </footer>

        )

    }

}