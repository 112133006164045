import React from 'react'

export default class InputAddon extends React.Component {

    render() {

        return (

            <div className='input-addon'>

                { this.props.children }

                { this.props.addon && <span>{ this.props.addon }</span> }

            </div>

        )

    }

}