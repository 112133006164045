import React from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import { get } from '../../util/API'

class Login extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            email:    '',
            error:    false,
            loading:  false,
            password: '',

        }

    }

    changeField( e ) {

        this.setState( { [ e.target.name ]: e.target.value } )

    }

    submit( e ) {

        e.preventDefault()

        this.setState( { error: false, loading: true }, () => {

            const err  = () => this.setState( { error: true } )

            get( '/Account/Login', { email: this.state.email, password: this.state.password }, { method: 'POST' } )
                .then( e => e.status > 0 && e.data && e.data.token ? this.success( e.data.token ) : err() )
                .catch( err )
                .finally( () => this.setState( { loading: false } ) )

        } )

    }

    success( token ) {

        this.props.onLogin( token )
        this.props.history.push( '/' )

    }

    render() {

        return (

            <Container className='slim'>

                <Form className='mb-5' onSubmit={ e => this.submit( e ) } action='/'>

                    <fieldset disabled={ this.state.loading }>

                        <Form.Control name='email'    value={ this.state.email }    onChange={ e => this.changeField( e ) } placeholder='Your Email Address' autoComplete='email'            type='email'    autoFocus       />

                        <Form.Control name='password' value={ this.state.password } onChange={ e => this.changeField( e ) } placeholder='Your Password'      autoComplete='current-password' type='password' className='my-2' />

                        <Button type='submit' className='w-100' disabled={ ! this.state.email || ! this.state.password }>Login</Button>

                        { this.state.error && <p className='text-center'>That did not work...</p> }

                        <p className='mt-3 text-center'><Link to='/recover-password'>Forgot your Password?</Link></p>

                    </fieldset>

                </Form>

            </Container>

        )

    }

}

export default withRouter( Login )