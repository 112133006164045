import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PageContent from './elements/PageContent'
import PageFooter from './elements/PageFooter'
import PageHeader from '../containers/elements/PageHeader'
import PageInner from './elements/PageInner'
import PageWrapper from './elements/PageWrapper'
import About from './pages/About'
import Login from '../containers/pages/Login'
import Map from './pages/Map'
import PrivacyPolicy from './pages/PrivacyPolicy'
import RecoverPassword from './pages/RecoverPassword'
import SignUp from './pages/SignUp'
import Startpage from './pages/Startpage'
import TOS from './pages/TOS'
import User from './pages/User'

export default class App extends React.Component {

    render() {

        return (

            <BrowserRouter>

                <PageWrapper>

                    <PageInner>

                        <PageHeader />

                        <PageContent>

                            <Switch>

                                <Route path='/about'            children={ <About           /> } />
                                <Route path='/login'            children={ <Login           /> } />
                                <Route path='/privacy-policy'   children={ <PrivacyPolicy   /> } />
                                <Route path='/recover-password' children={ <RecoverPassword /> } />
                                <Route path='/sign-up'          children={ <SignUp          /> } />
                                <Route path='/terms'            children={ <TOS             /> } />
                                <Route path='/:user/:map'       children={ <Map             /> } />
                                <Route path='/:user'            children={ <User            /> } />
                                <Route path='/'                 children={ <Startpage       /> } />

                            </Switch>

                        </PageContent>

                        <PageFooter />

                    </PageInner>

                </PageWrapper>

            </BrowserRouter>

        )

    }

}