import { LOGIN, LOGOUT, SET_USER } from '../constants/actionTypes'

const initialState = {

    token: null,
    user:  null,

}

const reducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case LOGIN: {

            const { token } = action.payload

            return { ...state, token }

        }

        case LOGOUT: {

            return initialState

        }

        case SET_USER: {

            const { user } = action.payload

            return { ...state, user }

        }

        default:

            return state

    }

}

export default reducer