import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import logo from '../../../img/maptk-logo.png'
import { get } from '../../util/API'
import Icon from './Icon'

class PageHeader extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            mainNavOpen: false,

        }

    }

    logout( e ) {

        e.preventDefault()

        get( '/Account/Logout', { token: this.props.token }, { method: 'POST' } ).finally( () => {

            this.props.onLogout()
            this.props.history.push( '/' )

            this.toggleMainNav()

        } )

    }

    toggleMainNav() {

        this.setState( { mainNavOpen: ! this.state.mainNavOpen } )

    }

    render() {

        return (

            <header className='page-header'>

                <Container className='h-100'>

                    <Row className='h-100 align-items-center'>

                        <Col>

                            <Link to='/'><img src={ logo } alt='maptk Logo' className='logo' /></Link>

                        </Col>

                        <Col xs='auto'>

                            <div className='main-nav-toggle' onClick={ () => this.toggleMainNav() }><Icon icon='bars' button /></div>

                            <div className={ 'main-nav' + ( this.state.mainNavOpen ? ' open' : '' ) }>

                                <ul>

                                    {

                                        this.props.token ?

                                            <>

                                                <li><Link to='/my-maps' onClick={ () => this.toggleMainNav() }>My Maps</Link></li>

                                                <li><Link to='/add-map' onClick={ () => this.toggleMainNav() }>Add Map</Link></li>

                                                <li><Link to='/settings' onClick={ () => this.toggleMainNav() }>Settings</Link></li>

                                                <li><Link to='/' onClick={ e => this.logout( e ) }>Logout</Link></li>

                                            </>

                                        :

                                            <>

                                                <li><Link to='/login' onClick={ () => this.toggleMainNav() }>Login</Link></li>

                                                <li><Link to='/sign-up' onClick={ () => this.toggleMainNav() }>Sign up</Link></li>

                                            </>

                                    }

                                </ul>

                            </div>

                        </Col>

                    </Row>

                </Container>

            </header>

        )

    }

}

export default withRouter( PageHeader )