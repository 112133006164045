import React from 'react'
import { Container } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

class User extends React.Component {

    render() {

        // TODO Page

        return (

            <Container>

                <h1>User</h1>

                <p>

                    User: { this.props.match.params.user }

                </p>

            </Container>

        )

    }

}

export default withRouter( User )