import { connect } from 'react-redux'
import Login from '../../components/pages/Login'
import { login } from '../../store/actions/auth'

const mapDispatchToProps = dispatch => {

    return {

        onLogin: token => dispatch( login( token ) ),

    }

}

export default connect( null, mapDispatchToProps )( Login )