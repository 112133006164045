import React from 'react'
import { Container } from 'react-bootstrap'

export default class PrivacyPolicy extends React.Component {

    render() {

        // TODO Page

        return (

            <Container>

                <h1>Privacy Policy</h1>

                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eget posuere mauris, a aliquet lectus. Nunc feugiat dictum eros non cursus. Phasellus ipsum ipsum, fermentum eget est a, molestie euismod ex. Proin blandit nunc sit amet elit gravida imperdiet in eget dolor. Suspendisse nec nunc fermentum, ornare sapien eget, blandit ligula. Sed viverra urna et volutpat consequat. Sed auctor in risus nec pulvinar. Aliquam hendrerit, urna id efficitur facilisis, diam lectus euismod dui, vel gravida neque orci at massa. Fusce in tortor ornare, luctus turpis at, vestibulum nibh. In viverra, metus et ultrices imperdiet, libero dui dignissim nibh, eget eleifend diam ligula eget leo.</p>

                <p>Etiam luctus lacinia nulla ut mattis. Quisque sed pellentesque sapien. Donec bibendum mollis laoreet. Aenean nec lacus pellentesque, vulputate enim maximus, luctus sapien. Etiam vestibulum blandit ipsum hendrerit rhoncus. Sed vel nisi mattis, mattis tellus non, rhoncus magna. Sed accumsan laoreet ullamcorper.</p>

                <p>Curabitur augue sem, imperdiet eu faucibus vel, efficitur a nisi. Donec molestie accumsan rutrum. Maecenas vitae urna a odio faucibus rutrum. Quisque eu arcu enim. Donec mattis purus a felis hendrerit, eu tincidunt dui pretium. Phasellus viverra lorem a est vestibulum convallis. Nam eget ante tortor. Integer pretium ut dui ac pellentesque. Integer fringilla lobortis arcu, quis tristique justo vehicula eu.</p>

                <p>Phasellus id sapien at leo hendrerit ornare. Nullam varius elit pulvinar felis aliquet, nec laoreet nisl ornare. Vestibulum ac metus ut eros efficitur dictum a sed nisl. Maecenas nulla nibh, iaculis mattis lorem vel, lacinia tincidunt sapien. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Maecenas ultrices luctus diam at facilisis. Mauris sagittis arcu vitae dolor venenatis, vel euismod quam vestibulum. Mauris molestie ligula eu nunc dignissim auctor. Sed ultricies bibendum massa nec dictum.</p>

                <p>Donec id nisi purus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam non magna condimentum justo interdum faucibus. Donec sollicitudin justo neque, vitae tempor libero volutpat sit amet. Aenean vestibulum varius lacus quis auctor. In auctor gravida elit sagittis aliquet. Aenean porttitor dolor vitae imperdiet tristique. In hac habitasse platea dictumst. Aliquam nec magna sit amet libero aliquet laoreet. Aliquam nec tellus non lacus dignissim vulputate ut sed turpis. Quisque in fringilla ipsum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Cras sollicitudin ligula sit amet condimentum efficitur.</p>

            </Container>

        )

    }

}