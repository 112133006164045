import React from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default class RecoverPassword extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            error:    false,
            loading:  false,
            username: '',

        }

    }

    changeField( e ) {

        this.setState( { [ e.target.name ]: e.target.value } )

    }

    submit( e ) {

        e.preventDefault()

        this.setState( { error: false, loading: true }, () => {

            // TODO Actually recover password...
            setTimeout( () => this.setState( { error: true, loading: false } ), 2000 )

        } )

    }

    render() {

        return (

            <Container className='slim'>

                <Form className='mb-5' onSubmit={ e => this.submit( e ) } action='/'>

                    <fieldset disabled={ this.state.loading }>

                        <Form.Control name='username' value={ this.state.username } onChange={ e => this.changeField( e ) } placeholder='Your Email Address' autoComplete='username' autoFocus className='mb-2' />

                        <Button type='submit' className='w-100' disabled={ ! this.state.username }>Recover Password</Button>

                        { this.state.error && <p className='text-center'>That did not work...</p> }

                        <p className='mt-3 text-center'><Link to='/login'>Back to Login</Link></p>

                    </fieldset>

                </Form>

            </Container>

        )

    }

}