const baseUrl = 'https://api.maptk.cc/'

export const get = async ( url, data, options = {} ) => {

    url = new URL( baseUrl + url )

    const defaultOptions = {

        body:        null,
        cache:       'no-cache',
        credentials: 'same-origin',
        method:      'GET',
        mode:        'cors',
        redirect:    'follow',
        referrer:    'no-referrer',

    }

    const init = {

        ...defaultOptions,
        ...options,

    }

    if ( data ) {

        if ( init.method === 'POST' ) {

            init.body = new FormData()

            Object.entries( data ).forEach( ( [ key, value ] ) => {

                if ( typeof value === 'object' && ! Array.isArray( value ) && value !== null && value.__arr && value.values && Array.isArray( value.values ) ) {

                    value.values.forEach( e => init.body.append( key, e ) )

                } else {

                    init.body.append( key, value )

                }

            } )

        } else {

            url.search = new URLSearchParams( data ).toString()

        }

    }

    return fetch( url, init ).then( e => e.json() )

}