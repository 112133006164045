import React from 'react'
import { Button, Container, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Icon from '../elements/Icon'
import InputAddon from '../elements/InputAddon'
import { get } from '../../util/API'
import { ACCOUNT_TYPE_FREEMIUM, ACCOUNT_TYPE_PREMIUM } from '../../util/constants'

export default class Login extends React.Component {

    #checkFieldTimers
    #checkSlugLengthTimer

    constructor( props ) {

        super( props )

        this.state = {

            accountId:        null,
            accountType:      null,
            fieldChecks:      { email: null, displayName: null, slug: null },
            displayName:      '',
            email:            '',
            error:            false,
            loading:          false,
            password:         '',
            paymentSucceeded: false,
            slug:             '',
            slugTooShort:     false,
            tosAccepted:      false,

        }

        this.#checkFieldTimers = {}

    }

    canBeSubmitted() {

        return (

            this.state.email &&
            this.state.password &&
            this.state.displayName &&
            this.state.slug.length > 2 &&
            this.state.accountType &&
            this.state.tosAccepted &&
            ! Object.keys( this.state.fieldChecks ).find( e => this.state.fieldChecks[ e ] !== true )

        )

    }

    changeSlug( slug ) {

        clearTimeout( this.#checkSlugLengthTimer )

        const state = { slug: slug.replace( /[^a-z0-9]/g, '' ).slice( 0, 20 ) }

        if ( this.state.slugTooShort && slug.length > 2 ) {

            state.slugTooShort = false

        }

        this.setState( state, () => {

            this.checkField( 'slug', 3 )

            this.#checkSlugLengthTimer = setTimeout( () => this.state.slug.length < 3 && this.setState( { slugTooShort: true } ), 1000 )

        } )

    }

    changeField( e ) {

        this.setState( { [ e.target.name ]: e.target.type === 'checkbox' ? e.target.checked : e.target.value }, () => ( e.target.name in this.state.fieldChecks ) && this.checkField( e.target.name ) )

    }

    checkField( field, minLength = 1 ) {

        clearTimeout( this.#checkFieldTimers[ field ] )

        const value = this.state[ field ]

        if ( value.length >= minLength ) {

            this.setState( { fieldChecks: { ...this.state.fieldChecks, [ field ]: 'checking' } }, () => {

                this.#checkFieldTimers[ field ] = setTimeout( () =>

                    get( '/System/CheckField', { entity: 'users', field, value }, { method: 'POST' } )
                        .then( e => this.setState( { fieldChecks: { ...this.state.fieldChecks, [ field ]: e.status > 0 ? e.data.available : false } } ) )
                        .catch( () => this.setState( { fieldChecks: { ...this.state.fieldChecks, [ field ]: false } } ) )

                , 1000 )

            } )

        } else {

            this.setState( { fieldChecks: { ...this.state.fieldChecks, [ field ]: null } } )

        }

    }

    getFieldCheck( field ) {

        return (

            this.state.fieldChecks[ field ] === 'checking' ?

                <Icon icon='spinner' spin />

            : this.state.fieldChecks[ field ] === true ?

                <Icon icon='check' />

            : this.state.fieldChecks[ field ] === false ?

                <OverlayTrigger overlay={ <Tooltip>Already in use</Tooltip> }><div><Icon icon='exclamation-triangle' /></div></OverlayTrigger>

            :

                null

        )

    }

    processPayment() {

        // TODO

        alert( 'You are lucky: Payment has not been implemented yet. Your Premium Account is free. Enjoy!' )

        this.register()

    }

    register() {

        this.setState( { error: false, loading: true }, () => {

            const err  = () => this.setState( { error: true } )
            const data = {

                email:       this.state.email,
                password:    this.state.password,
                displayName: this.state.displayName,
                slug:        this.state.slug,
                accountType: this.state.accountType,

            }

            get( '/Account/Register', data, { method: 'POST' } )
                .then( e => e.status > 0 ? this.setState( { accountId: e.data.accountId } ) : err() )
                .catch( err )
                .finally( () => this.setState( { loading: false } ) )

        } )

    }

    submit( e ) {

        e.preventDefault()

        if ( this.canBeSubmitted() ) {

            if ( this.state.accountType === ACCOUNT_TYPE_PREMIUM ) {

                this.processPayment()

            } else {

                this.register()

            }

        }

    }

    renderRegistrationForm() {

        return (

            <Form className='mb-5' onSubmit={ e => this.submit( e ) } action='/' autoComplete='off'>

                <fieldset disabled={ this.state.loading }>

                    <div>

                        <InputAddon addon={ this.getFieldCheck( 'email' ) }>

                            <Form.Control name='email' value={ this.state.email } onChange={ e => this.changeField( e ) } placeholder='Your Email Address' type='email' autoFocus />

                        </InputAddon>

                        <Form.Text className='text-muted mx-3 d-block'>You'll receive an email to confirm your account.</Form.Text>

                        { this.state.fieldChecks.email === false && <Form.Text className='text-warning mx-3 d-block'>This email address is already in use.<br />Forgot your password? <Link to='/recover-password'>Recover it!</Link></Form.Text> }

                    </div>

                    <div className={ 'mt-4 transition-all opacity-0 ' + ( this.state.email ? 'opacity-100' : 'invisible' ) }>

                        <Form.Control name='password' value={ this.state.password } onChange={ e => this.changeField( e ) } placeholder='Your Password' type='password' autoComplete='sign-up-password' />

                        <Form.Text className='text-muted mx-3 d-block'>Please choose a strong password and remember or store it.</Form.Text>

                    </div>

                    <div className={ 'mt-4 transition-all opacity-0 ' + ( this.state.email && this.state.password ? 'opacity-100' : 'invisible' ) }>

                        <InputAddon addon={ this.getFieldCheck( 'displayName' ) }>

                            <Form.Control name='displayName' value={ this.state.displayName } onChange={ e => this.changeField( e ) } placeholder='Your Display Name' />

                        </InputAddon>

                        <Form.Text className='text-muted mx-3 d-block'>The name displayed on your maps, in lists and in search, e.g. Jon Doe.</Form.Text>

                        { this.state.fieldChecks.displayName === false && <Form.Text className='text-warning mx-3 d-block'>This display name is already in use. Try another one.</Form.Text> }

                    </div>

                    <div className={ 'mt-4 transition-all opacity-0 ' + ( this.state.email && this.state.password && this.state.displayName ? 'opacity-100' : 'invisible' ) }>

                        <InputAddon addon={ this.getFieldCheck( 'slug' ) }>

                            <Form.Control value={ this.state.slug } onChange={ e => this.changeSlug( e.target.value ) } placeholder='Your Slug' autoCapitalize='none' />

                        </InputAddon>

                        <Form.Text className='text-muted mx-3 d-block'>This will used for your personal URL. It my contain lowercase letters and numbers and must be between 3 and 20 characters long. Choose wisely.</Form.Text>

                        { this.state.fieldChecks.slug === false && <Form.Text className='text-warning mx-3 d-block'>This slug is already in use. Try another one.</Form.Text> }

                        { this.state.slugTooShort && this.state.slug.length > 0 && <Form.Text className='text-warning mx-3 d-block'>At least three characters.</Form.Text> }

                        <div className={ 'transition-all opacity-0 ' + ( this.state.slug ? 'opacity-100' : 'invisible' ) }>

                            <Form.Text className='text-muted ms-3 mt-2 d-block'>Your User URL</Form.Text>

                            <p className='mx-3'>maptk.cc/{ this.state.slug }</p>

                            <Form.Text className='text-muted mx-3 mt-2 d-block'>Your Map URL's</Form.Text>

                            <p className='mx-3'>maptk.cc/{ this.state.slug }/intergalactic-championships-{ new Date().getFullYear() }</p>

                        </div>

                    </div>

                    <div className={ 'my-5 transition-all opacity-0 ' + ( this.state.email && this.state.password && this.state.displayName && this.state.slug ? 'opacity-100' : 'invisible' ) }>

                        <p className='text-center'>Select an Account Type:</p>

                        <div className='mega-option'>

                            <div className={ 'selector selector-' + ( this.state.accountType || 0 ) } />

                            <div className='option' onClick={ () => this.changeField( { target: { name: 'accountType', value: ACCOUNT_TYPE_PREMIUM } } ) }>

                                <h4>

                                    Premium

                                    <span className='price'>€ 9.00 / Year</span>

                                </h4>

                                <ul className='features'>

                                    <li>Unlimited Maps</li>

                                    <li>Draw your Route on a Map</li>

                                    <li>Georeference Maps without a Route</li>

                                </ul>

                                <p>Requires a PayPal Subscription.</p>

                            </div>

                            <div className='option' onClick={ () => this.changeField( { target: { name: 'accountType', value: ACCOUNT_TYPE_FREEMIUM } } ) }>

                                <h4>

                                    Freemium

                                    <span className='price'>€ 0.00 / Year</span>

                                </h4>

                                <ul className='features'>

                                    <li>One Map a Day</li>

                                    <li>Draw your Route on a Map</li>

                                    <li>Georeference Maps without a Route</li>

                                </ul>

                                <p>Does not require a PayPal Subscription.</p>

                            </div>

                        </div>

                    </div>

                    <div className={ 'transition-all opacity-0 ' + ( this.state.email && this.state.password && this.state.displayName && this.state.slug && this.state.accountType ? 'opacity-100' : 'invisible' ) }>

                        <Form.Check onChange={ e => this.changeField( e ) } name='tosAccepted' id='tosAccepted' checked={ this.state.tosAccepted } label={ <>I accept the <a href='/terms' target='_blank'>Terms of Service</a>.</> } />

                    </div>

                    <div className={ 'mt-4 transition-all opacity-0 ' + ( this.canBeSubmitted() ? 'opacity-100' : 'invisible' ) }>

                        <Button type='submit' className='w-100'>{ this.state.accountType === ACCOUNT_TYPE_PREMIUM ? 'Continue with PayPal' : 'Create free Account' }</Button>

                        { this.state.error && <p className='text-center'>That did not work...</p> }

                    </div>

                </fieldset>

            </Form>

        )

    }

    renderSuccessMessage() {

        return (

            <>

                <h3>Thanks for signing up!</h3>

                <p>You will receive an email to activate your account. You might want to check your Spam folder as well.</p>

                <p>You can already login but you won't be able to do anything until you have activated your account.</p>

                <Link to='/login' className='btn btn-primary w-100 mt-5'>Continue to Login</Link>

            </>

        )

    }

    render() {

        return (

            <Container className='slim'>

                {

                    this.state.accountId ?

                        this.renderSuccessMessage()

                    :

                        this.renderRegistrationForm()

                }

            </Container>

        )

    }

}