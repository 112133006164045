import React from 'react'

export default class Icon extends React.Component {

    render() {

        const className = []

        className.push( 'fa' + ( 'solid' in this.props ? '-solid' : 'regular' in this.props ? '-regular' : '' ) )
        className.push( 'fa-' + this.props.icon                                                                 )

        'size'      in this.props && className.push( 'fa-' + this.props.size + 'x' )
        'fw'        in this.props && className.push( 'fa-fw'                       )
        'spin'      in this.props && className.push( 'fa-spin'                     )
        'beat'      in this.props && className.push( 'fa-beat'                     )
        'fade'      in this.props && className.push( 'fa-fade'                     )
        'beatFade'  in this.props && className.push( 'fa-beat-fade'                )
        'bounce'    in this.props && className.push( 'fa-bounce'                   )
        'flip'      in this.props && className.push( 'fa-flip'                     )
        'shake'     in this.props && className.push( 'fa-shake'                    )
        'className' in this.props && className.push( this.props.className          )

        const props = {

            className: className.join( ' ' ),
            onClick:   this.props.onClick,

        }

        if ( 'button' in this.props ) {

            props.role = 'button'

        }

        return (

            <i { ...props } />

        )

    }

}