import { LOGIN, LOGOUT, SET_USER } from '../constants/actionTypes'

export const login = token => {

    return {

        type:    LOGIN,
        payload: { token },

    }

}

export const logout = () => {

    return {

        type:    LOGOUT,
        payload: {},

    }

}

export const setUser = user => {

    return {

        type:    SET_USER,
        payload: { user },

    }

}