import React from 'react'
import { Link } from 'react-router-dom'

export default class MapPreview extends React.Component {

    render() {

        return (

            <div className='map-preview'>

                <div className='img'><img src={ this.props.map.img } alt='' /></div>

                <div className='overlay' />

                <div className='info'>

                    <h3><Link to={ '/' + this.props.map.userSlug + '/' + this.props.map.mapSlug }>{ this.props.map.title }</Link></h3>

                    <div className='details'>

                        <div className='user'><Link to={ '/' + this.props.map.userSlug }>{ this.props.map.user }</Link></div>

                        <div className='date'>{ this.props.map.date }</div>

                    </div>

                </div>

            </div>

        )

    }

}