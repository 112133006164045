import React from 'react'
import { Container, Form } from 'react-bootstrap'
import MapPreview from '../elements/MapPreview'

export default class Startpage extends React.Component {

    render() {

        // TODO Page

        const maps = [

            { date: 'So, 26.03.2023', img: 'https://maps.plohni.com/map_images/3616.thumbnail.jpg', title: 'ÖM Mannschaft',              user: 'Markus Plohn',       userSlug: 'plohni',  mapSlug: 'om-mannschaft'              },
            { date: 'Sa, 25.03.2023', img: 'https://maps.plohni.com/map_images/3615.thumbnail.jpg', title: '1. Austria Cup',             user: 'Markus Plohn',       userSlug: 'plohni',  mapSlug: '1-austria-cup'              },
            { date: 'So, 26.03.2023', img: 'https://maps.plohni.com/map_images/3614.thumbnail.jpg', title: 'ÖM Mannschaft',              user: 'Martin Veitsberger', userSlug: 'veitsi',  mapSlug: 'om-mannschaft'              },
            { date: 'Sa, 25.03.2023', img: 'https://maps.plohni.com/map_images/3613.thumbnail.jpg', title: '1.Austria Cup Langdistanz',  user: 'Martin Veitsberger', userSlug: 'veitsi',  mapSlug: '1-austria-cup-langdistanz'  },
            { date: 'So, 26.03.2023', img: 'https://maps.plohni.com/map_images/3612.thumbnail.jpg', title: 'ÖM Mannschaft - Einzel',     user: 'Dominik Lapornik',   userSlug: 'dominik', mapSlug: 'om-mannschaft-einzel'       },
            { date: 'Sa, 25.03.2023', img: 'https://maps.plohni.com/map_images/3611.thumbnail.jpg', title: '1.AC - Langdistanz',         user: 'Dominik Lapornik',   userSlug: 'dominik', mapSlug: '1-ac-langdistanz'           },
            { date: 'So, 19.03.2023', img: 'https://maps.plohni.com/map_images/3610.thumbnail.jpg', title: 'CESOM, 2. Etappe',           user: 'Markus Plohn',       userSlug: 'plohni',  mapSlug: 'cesom-2-etappe'             },
            { date: 'Sa, 18.03.2023', img: 'https://maps.plohni.com/map_images/3607.thumbnail.jpg', title: 'CESOM, 1. Etappe',           user: 'Markus Plohn',       userSlug: 'plohni',  mapSlug: 'cesom-1-etappe'             },
            { date: 'So, 12.03.2023', img: 'https://maps.plohni.com/map_images/3606.thumbnail.jpg', title: 'Lipica Open, 2. Etappe',     user: 'Markus Plohn',       userSlug: 'plohni',  mapSlug: 'lipica-open-2-etappe'       },
            { date: 'Sa, 11.03.2023', img: 'https://maps.plohni.com/map_images/3605.thumbnail.jpg', title: 'Lipica Open, 1. Etappe',     user: 'Markus Plohn',       userSlug: 'plohni',  mapSlug: 'lipica-open-1-etappe'       },

        ]

        return (

            <Container>

                <Form className='mb-5'>

                    <Form.Control placeholder='Filter by Map Name, User, Date etc.' />

                </Form>

                <div className='map-previews'>

                    { maps.map( ( e, i ) => <MapPreview key={ i } map={ e } /> ) }

                </div>

            </Container>

        )

    }

}