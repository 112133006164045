import { connect } from 'react-redux'
import PageHeader from '../../components/elements/PageHeader'
import { logout } from '../../store/actions/auth'

const mapStateToProps = state => {

    return {

        token: state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onLogout: token => dispatch( logout() ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( PageHeader )