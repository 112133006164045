import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { createStore } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import reducers from './js/store/reducers'
import App from './js/components/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './scss/index.scss'

const root  = ReactDOM.createRoot( document.getElementById( 'root' ) )
const store = createStore( persistReducer( { key: 'maptk-root', storage, blacklist: [] }, reducers ) )

root.render(

    <Provider store={ store }>

        <PersistGate persistor={ persistStore( store ) }>

            <App />

        </PersistGate>

    </Provider>

)

serviceWorkerRegistration.register()